import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import "../css/Home/auth.css";
import navimg from "../image/logo.png";
import {
  getBalance,
  idToAddress,
  isUserExists,
  registerExt,
} from "../helpers/web3";

import { setUserExist } from "../helpers/redux/dataSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getUserRandomId } from "../helpers/api";

const Registeration = () => {
  const route = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userExist, walletAddress, message } = useSelector(
    (state) => state.user.value
  );
  const [sponserId, setSponserId] = useState("");
  const [refreshReg, setRefresh] = useState(false);
  const [regloading,setRegLoading] = useState(false)
  function register() {
    if (sponserId === "") {
      toast.error("Enter Sponser Id");
    } else {
      getUserRandomId(sponserId).then((userID) => {
        if (userID[0]?.id) {
          idToAddress(userID[0]?.id).then((res) => {
            if (!res.includes("0x0000")) {
              if (userExist === false) {
                getBalance(walletAddress).then((res1) => {
                  if (res1 > 0) {
                    registerExt(
                      walletAddress,
                      res,
                       10,
                      setRefresh, 
                      setRegLoading,
                      dispatch,
                      navigate
                    );
                  } else {
                    toast.error("Insufficient Wallet Balance!");
                  }
                });
              } else {
                toast.error("user already exist!");
              }
            } else {
              toast.error("Invalid Sponser ID");
            }
          });
        } else {
          toast.error("Invalid Sponser ID");
          //   NotificationManager.error("Enter Sponser Id");
        }
        console.log("res::::arr");
      });
    }
  }

  useEffect(() => {
    isUserExists(walletAddress).then((res) => {
      dispatch(setUserExist({ userExist: res }));
    });

    if (route.search) {
      const code =
        route?.search.indexOf("?") > -1 ? route?.search?.split("?")[1] : 0;

      if (code) setSponserId(code);
    }
  }, [refreshReg, route, walletAddress]);

  return (
    <>
      <section className="main-dash-container">
        <div className="siginin-section">
          <div className="signin-middle-container">
            <div className="container">
              <div className="col-md-12 shadow">
               
                <div className="col-md-12">
                  <div className="col-lg-6 col-md-8 shadow  m-auto">
                    <div className="card outer-form-signin">
                      <div className="card-body">
                      <h5 className="text-white fw-normal h4">SignUp</h5>
                    <div className="mb-2 mt-4">
                      <Link to="/">
                        <img
                          src={navimg}
                          className="nav-image"
                          alt=""
                          width={60}
                        />
                      </Link>
                    </div>
                    <div className="inner-form-signin col-md-12 py-0">
                      <div className="row">
                         
                         
                          <input
                            type="text"
                            id="ip2"
                            className="form-control mb-3 mt-2"
                            placeholder="Wallet Address"
                            value={walletAddress}
                          />
                          <input
                            type="text"
                            id="ip2"
                            className="refral address form-control"
                            placeholder="Refral Id"
                            value={sponserId}
                            onChange={(e) => {
                              setSponserId(e.target.value);
                            }}
                          />
                          
                          <div className="px-0 d-grid">
                        {
                         regloading ? 
                         (
                          <button
                          class="btn btn-primary mt-2">
                          <div class="spinner-border text-light" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        </button>)
                        :(<button
                          class="btn btn-primary mt-2"
                          onClick={() => register()}
                        >
                          SignUp
                        </button>)}
                        </div>

                        <div className="col-md-12 py-2">
                          <span className="text-secondary">
                           Already have account ?{" "}
                          </span>
                          <a href="login" className="ms-1">
                            Login Here
                          </a>
                        </div>
                        <div>
                            <span className=" text-secondary">Note : </span>
                            <span
                              className="text-login-heading text-secondary"
                              style={{ fontSize: "14px" }}
                            >
                              {message}!
                            </span>
                          </div>
                        </div>
                       
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pb-4 auth-foot">
                 
                  {/* <div className="col-md-6">
                    <p className="text-secondary pt-3">
                      Official chat Telegram:
                    </p>
                    <Link to="">
                      <h4 className="tele-color">@metalifeglobal.space</h4>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Registeration;
