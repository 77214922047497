import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import "../../src/css/Home/dashuser.css";
import { HiArrowSmUp } from "react-icons/hi";
import { IoMdArrowRoundDown } from "react-icons/io";
import { TbArrowsHorizontal } from "react-icons/tb";
import { Pagination } from "@mui/material";
import ToggleSidebar from "./ToggleSidebar";
import { getDirectTeam } from "../helpers/api";
import { useSelector ,useDispatch} from "react-redux";
import "../css/Home/Partner.css";
import { Loader } from "./Loader";
import { setLevelStatus } from "../helpers/redux/dataSlice";
const Partners = () => {
  const [apidata, setApidata] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { userInfo,levelStatus,workingTreeArray } = useSelector((state) => state.user.value);
  useEffect(() => {
    getDirectTeam(userInfo?.id, page).then((res) => {
      
      setTotal(res?.total);
      setApidata(res?.data);
      setLoading(true)
    });
    const countActiveLevel = workingTreeArray?.filter((data) => {
      return data.active === true;
    });
    dispatch(setLevelStatus({ levelStatus: countActiveLevel?.length}));
  }, [userInfo, page]);

  const handleChange = (e, p) => {
    getDirectTeam(userInfo?.id, p).then((res) => {
      setTotal(res?.total);
      setApidata(res?.data);
      setLoading(true)
    });
    //console.log(p, page, "page:::");
    setPage(p);
  };
  return (
    <>
      <section className="main-dash-container">
        <ToggleSidebar />
        <div className="container" style={{ paddingTop: "100px" }}>

          <h2 className="text-center text-warning pb-2 dash-heading">Direct Partners</h2>
          {loading === false ? (
                <Loader/>
              ) : (
          <div className="row">
            <div className="table-responsive table-dark user-table user-dash-section  p-2">
              <table class="table table-borderless table-striped">
                <thead>
                  <tr>
                    <th className="table-head"># </th>
                    <th className="table-head dash-heading"  style={{ minWidth: "110px" }}>Txn Hash</th>
                    <th className="table-head dash-heading"  style={{ minWidth: "110px" }}>User Address</th>
                    <th className="table-head dash-heading" style={{ minWidth: "63px" }}>
                      User Id
                    </th>
                    <th className="table-head dash-heading" style={{ minWidth: "100px" }}>
                     Active Slot
                    </th>
                    <th className="table-head dash-heading" style={{ minWidth: "100px" }}>
                   User Direct
                    </th>
                    
                    <th className="table-head dash-heading" style={{ minWidth: "130px" }}>
                      Registration Date
                    </th>
                  </tr>
                </thead>
                <tbody className="table-data">
                  {apidata?.length > 0 ? (                   
                    apidata?.map((e, index) => {                     
                      return (
                        <tr
                          className="  p-2"
                          style={{ marginBlock: "0px 15px" }}
                          key={index + "apidata"}
                        >
                          <td className="">
                            <span>{index + 1}</span>
                          </td>
                          <td className="">
                            <span>
                              {e?.transaction_id.slice(0, 6)}...
                              {e?.transaction_id.slice(-5)}
                            </span>
                          </td>
                          <td className="">
                            <span>
                              {e?.user.slice(0, 6)}...
                              {e?.user.slice(-5)}
                            </span>
                          </td>
                          <td className="">
                            <span>{e?.user_id} </span>
                          </td>
                          <td className="">
                          {/* <span><HiArrowSmUp color="green"/></span> <span><IoMdArrowRoundDown color="red" /> </span> <span><TbArrowsHorizontal color="yellow"/></span>  </span> */}
                            <span>  
                            {e?.level}   
   
                            {e?.level==levelStatus? <span className="ps-1"><TbArrowsHorizontal color="#fffb31"  size={20}/> </span> : e?.level>levelStatus?<span className="ps-1"><HiArrowSmUp color="red" size={20}/></span>:<span className="ps-1"><IoMdArrowRoundDown color="#47ff47 "  size={20}/> </span>
                              
                              } 
                            </span>
                          </td>
                          <td className="">
                           {e?.direct_referrals}
                          </td>
                          <td className="">
                            {moment(Number(e.block_timestamp) * 1000).fromNow()}
                            {}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className="text-center text-white mt-4 mb-2 fw-bold fs-5 dash-heading">
                          No Data Found.
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="d-flex justify-content-center mb-3 mt-3">
                <Pagination
                  color="primary"
                  count={Math.ceil(total / 10)}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>)}
        </div>
      </section>
    </>
  );
};

export default Partners;
