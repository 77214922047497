import React from "react";
import { Link } from "react-router-dom";
import navimg from "../image/logo.png";

const Header = () => {
  return (
    <>
      <nav className="navbar nav nav-header">
        <div className="container pt-2">
          <div className="">
            <Link className="navbar-brand" to="/">
              <img src={navimg} className="nav-image" alt=""  height={80} />
            </Link>
          </div>
          
          <div className="nav-list">
            <Link to="" className="text-white ps-2 pe-2">
              How to Start
            </Link>
            <a href="login" className="custom-yellow-text ps-2 pe-2">
              Login
            </a>
            <a href="register" className="custom-yellow-text ps-2 pe-2">
              Signup
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
