import { createSlice } from "@reduxjs/toolkit";
const initialValue = {
  walletAddress: "",
  userExist: false,
  userInfo:[],
  workingTreeArray:[],
  nonWorkingTreeArray:[],
  refresh:false,
  message:'Please make sure you are connected with Polygon Network',
  levelStatus:0,
};
const dataSlice = createSlice({
  name: "dataSlice",
  initialState: {
    value: initialValue,
  },
  reducers: {
    setWalletAddress: (state, action) => {
        state.value.walletAddress = action.payload.walletAddress;
    },
    setUserExist: (state, action) => {
      state.value.userExist = action.payload.userExist;
    },
    setUserInfo: (state, action) => {
      state.value.userInfo = action.payload.userInfo;
    },
    setWorkingTreeArray: (state, action) => {
      state.value.workingTreeArray = action.payload.data;
    },
    setNonWorkingTreeArray: (state, action) => {
      state.value.nonWorkingTreeArray = action.payload.data;
    },
    setRefresh: (state, action) => {
      state.value.refresh = action.payload.data;
    },
    setMessage:(state,action)=>{
      state.value.message = action.payload.message
    },
    setLevelStatus:(state,action)=>{
      state.value.levelStatus = action.payload.levelStatus;
    }
  },
});

export const { setUserExist, setWalletAddress,setUserInfo,setWorkingTreeArray,setNonWorkingTreeArray,setRefresh, setMessage , setLevelStatus} = dataSlice.actions;
export default dataSlice.reducer;



