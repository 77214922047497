import React from "react";
import '../css/Home/home.css'
import Header from "../Pages/Header";
import Banner from "../Pages/Banner";
import About from "../Pages/About";
import Earn from "../Pages/Earn";
import Work from "../Pages/Work";
import Member from "../Pages/Member";
import Roadmap from "../Pages/Roadmap";
import News from "../Pages/News";
import Footer from "../Pages/Footer";
import Mission from "../Pages/Mission";
 const Home = () => {
  return (
    <>
       <Header/>
       <Banner/>
       <About/>      
       <Work/>
       <Mission/>
       <Earn/>
       {/* <Member/> */}
       <Roadmap/>
       {/* <News/> */}
       <Footer/>
    </>
  );
};

export default Home;
