import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Registeration from "./components/Registeration";
import WorkingTree from "./Pages/WorkingTree";
import {
  setUserExist,
  setUserInfo,
  setWalletAddress,
} from "./helpers/redux/dataSlice";
import { isUserExists, users } from "./helpers/web3";
import Partners from "./Pages/Partners";
import { fetchActiveLevel, verifyUser } from "./helpers/helperFunction";
import UserIncome from "./Pages/UserIncome";
import WorkingTable from "./Pages/WorkingTable";
import Home from "./components/Home";
import ReinvestDeduction from "./Pages/ReinvestDeduction";
import UserPoolIncom from "./Pages/UserPoolIncom";
import UserDirectIncome from "./Pages/UserDirectIncome";
import CoinWalletIncome from "./Pages/CoinWalletIncome";

const RoutesMain = () => {
  const { userExist, walletAddress, refresh } = useSelector(
    (state) => state.user.value
  );
  const dispatch = useDispatch();
  const route = useLocation();

  function getAddressFromLocalStorage() {
    const localwaddress = localStorage.getItem("volcano_waddress");
    if (localwaddress) {
      dispatch(setWalletAddress({ walletAddress: localwaddress }));
      isUserExists(localwaddress).then((res) => {
        dispatch(setUserExist({ userExist: res }));
      });
    } else {
      verifyUser(dispatch, "", toast);
    }
  }

  function getViewAddressFromUrl() {
    const url = route?.search;
    const address = url?.substring(url?.indexOf("=") + 1);
    if (address && address?.length>25) {
      dispatch(setWalletAddress({ walletAddress: address }));
      isUserExists(address).then((res) => {
        dispatch(setUserExist({ userExist: res }));
      });
    } else {
      getAddressFromLocalStorage();
    }
  }
  
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        verifyUser(dispatch, "", toast);
      });
    }
    getViewAddressFromUrl();
  }, []);

  useEffect(() => {
    if (walletAddress) {
      fetchActiveLevel(walletAddress, dispatch);
      users(walletAddress).then((res) => {
        dispatch(setUserInfo({ userInfo: res }));
      });
    } else {
      console.log("wallet address not fetched", walletAddress);
    }
  }, [walletAddress, refresh]);

  //console.log()

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Registeration />} />
        <Route
          path="/dashboard"
          element={userExist ? <Dashboard /> : <Navigate to="/login" />}
        />

        <Route
          path="/login"
          element={userExist ? <Navigate to="/dashboard" /> : <Login />}
        />
        <Route
          path="/partners"
          element={userExist ? <Partners /> : <Navigate to="/login" />}
        />
        <Route
          path="/workingtree"
          element={userExist ? <WorkingTree /> : <Login />}
        />
        <Route
          path="/userIncome"
          element={userExist ? <UserIncome /> : <Login />}
        />
          <Route
          path="/userPoolIncome"
          element={userExist ? <UserPoolIncom /> : <Login />}
        />
         <Route
          path="/userDirectIncome"
          element={userExist ? <UserDirectIncome /> : <Login />}
        />
        <Route
          path="/reinvestDeduction"
          element={userExist ? <ReinvestDeduction /> : <Login />}
        />
        <Route
          path="/coinWalletIncome"
          element={userExist ? <CoinWalletIncome/> : <Login />}
        />
        <Route
          path="/workingtable"
          element={userExist ? <WorkingTable /> : <Login />}
        >
          <Route
            path=":level"
            element={userExist ? <WorkingTable /> : <Login />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default RoutesMain;
