import React from "react";
import { Link } from "react-router-dom";
import footerImg from "../image/logo.png";
const Footer = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-4">

          <div className="text-center">
          <Link to="/">
            <img src={footerImg} alt="firstHeroGrid"  height={70} width={70}/>
            </Link>
            <div>
           
              <p>
                Volcano Money 
              </p>
            
            </div>
            </div>
          </div>

          <div className="col-md-4">
            {/* <p className="d-flex justify-content-center align-items-center mt-5 ">
              Terms of use of the site.{" "}
            </p> */}
          </div>
          <div className="col-md-4">
            {/* <div className=" d-flex justify-content-evenly mt-2">
              <div>How to Start</div>
              <div>Academy</div>
              <div>News</div>
            </div> */}
            <p className="d-flex justify-content-center align-items-center mt-5 ">
              © 2023 All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
