import Web3 from "web3";
import { Interface } from "@ethersproject/abi";
//import { toast } from 'react-toastify';
import { toast } from "react-hot-toast";
import {
  CONTRACT_ADDRESS,
  TOKEN_ADDRESS,
  CONTRACT_ABI,
  TOKEN_ABI,
  MultiCall_ABI,
  Multicall_ADDRESS,
} from "./config";
import { fetchActiveLevel, verifyUser } from "./helperFunction";

const web3 = new Web3(Web3.givenProvider);
const contract_instance = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
const token_instance = new web3.eth.Contract(TOKEN_ABI, TOKEN_ADDRESS);

export function web3lelo() {
  if (web3) {
    return web3;
  }
}

export async function startNow() {
  return new Promise(async (resolve, reject) => {
    if (window.ethereum) {
      const chain_id = await web3.eth.getChainId();

      if (chain_id == 137) {
        try {
          window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then(async function (address) {
              const userAddress = address[0];
              resolve({
                userAddress,
              });
            });
        } catch (error) {
          reject(error);
        }
      } else {
        toast.error("Please Connect With Polygon Network");
      }
    } else {
      toast.error("Open in Dapp Browser or Install Any Crypto Wallet");
    }
  });
}

export async function registerExt(
  address,
  refAddress,
  amount,
  setRefresh,
  setRegLoading,
  dispatch,
  navigate
) {
  try {
    console.log(address, refAddress, amount, "register");
    setRegLoading(true);

    token_instance.methods
      .balanceOf(address)
      .call()
      .then((res) => {
        const userBalance = res / 1e18;
        console.log(res, "userBalance");
        // if (userBalance >= amount) {
        if (userBalance >= amount) {
          token_instance.methods
            .allowance(address, contract_instance._address)
            .call()
            .then(async (res) => {
              const allowance = res / 1e18;

              if (allowance >= amount) {
                try {
                  console.log(refAddress, "refId");
                  const gasPrice = await web3.eth.getGasPrice();
                  const gas = await contract_instance.methods
                    .registrationExt(refAddress)
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });

                  const data = contract_instance.methods
                    .registrationExt(refAddress)
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: gas,
                    });
                  toast
                    .promise(data, {
                      loading: <b>registeration Pending...</b>,
                      success: () => {
                        return <b>registeration Successful</b>;
                      },
                      error: <b>registeration Canceld.</b>,
                    })
                    .then((d) => {
                      setRegLoading(false);
                      setRefresh(true);
                      fetchActiveLevel(address, dispatch);
                      verifyUser(dispatch);
                      navigate("/dashboard");
                    })
                    .catch((error) => {
                      setRegLoading(false);
                      console.log(error, "Error");
                    });
                } catch (error) {
                  setRegLoading(false);
                  console.log(error, "Error");
                }
              } else if (allowance < amount) {
                console.log("6");
                console.log((amount * 1e18).toString(), "amount*1e18 3");
                try {
                  const gasPrice = await web3.eth.getGasPrice();
                  const approveGas = await token_instance.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });
                  const approveData = token_instance.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: approveGas,
                    });
                  toast
                    .promise(approveData, {
                      loading: <b>Approval Pending...</b>,
                      success: <b>Approval Successful</b>,
                      error: <b>Approval request failed.</b>,
                    })
                    .then(async () => {
                      const gas = await contract_instance.methods
                        .registrationExt(refAddress)
                        .estimateGas({
                          from: address,
                          value: 0,
                          gasPrice: gasPrice,
                        });
                      const data = contract_instance.methods
                        .registrationExt(refAddress)
                        .send({
                          from: address,
                          value: 0,
                          gas: gas,
                          gasPrice: gasPrice,
                        });
                      toast
                        .promise(data, {
                          loading: <b> Transaction Pending...</b>,
                          success: () => {
                            setRegLoading(true);
                            setRefresh(true);
                            fetchActiveLevel(address, dispatch);
                            verifyUser(dispatch);
                            navigate("/dashboard");
                            return <b>Transaction Successful</b>;
                          },
                          error: <b>Transaction Canceld.</b>,
                        })
                        .then((d) => {
                          setRegLoading(false);
                          setRefresh(true);
                          fetchActiveLevel(address, dispatch);
                          verifyUser(dispatch);
                          navigate("/dashboard");
                        })
                        .catch((error) => {
                          setRegLoading(false);
                          console.log(error, "Error");
                        });
                    })
                    .catch((err) => {
                      setRegLoading(false);
                      console.log(err, "Err-4");
                    });
                } catch (error) {
                  setRegLoading(false);
                  console.log(error, "approval Error");
                }
              }
            })
            .catch((err) => {
              setRegLoading(false);
              console.log(err, "Err-2");
            });
        } else {
          setRegLoading(false);
          toast.error("Insufficient Balance !");
        }
      })
      .catch((err) => {
        setRegLoading(false);
        console.log(err, "Err -1");
      });
  } catch (error) {
    setRegLoading(false);
    console.log(error);
  }
}

// export function registerExt(
//   address,
//   refAddress,
//   amount,
//   setRefresh,
//   dispatch,
//   navigate
// ) {
//   try {
//     console.log(address, refAddress, "refId");
//     const data = contract_instance.methods
//       .registrationExt(refAddress)
//       .send({ from: address });
//     toast.promise(data, {
//       loading: "registeration Pending...",
//       success: () => {
//         setRefresh(true);
//         verifyUser(dispatch);
//         navigate("/dashboard");
//         return "registeration Successful";
//       },
//       error: "registeration Canceld.",
//     });
//   } catch (error) {
//     console.log(error, "Error");
//   }
// }

export async function withdrawHoldingAnount(address, level, setRefresh) {
  try {
    console.log(address, level, "with::::");
    const data = contract_instance.methods
      .withdrawHolding(address, level)
      .send({ from: address });
    toast.promise(data, {
      loading: <b>Withraw Pending...</b>,
      success: () => {
        setRefresh(true);
        return <b>Withraw Successful</b>;
      },
      error: <b>Withraw Canceld.</b>,
    });
  } catch (error) {
    console.log(error, "Error");
  }
}
export async function isUserExists(address) {
  const data = await contract_instance.methods.isUserExists(address).call();
  return data;
}

export async function idToAddress(id) {
  try {
    const data = await contract_instance.methods.idToAddress(id).call();
    return data;
  } catch (e) {
    console.log(e, "error in idtoaddress metohd");
  }
}

export async function lastUserId() {
  const data = await contract_instance.methods.lastUserId().call();
  return data;
}

export async function users(address) {
  const data = await contract_instance.methods.users(address).call();

  return data;
}

export async function checkUsersActiveX6Level(address, level) {
  const data = await contract_instance.methods
    .usersActiveX6Levels(address, level)
    .call();
  return data;
}
export async function usersX6HoldAmount(address, level) {
  const data = await contract_instance.methods
    .userX6HoldAmount(address, level)
    .call();
  return data;
}

export async function getLevelMulticall(calls, arr) {
  try {
    const multi = new web3.eth.Contract(MultiCall_ABI, Multicall_ADDRESS);
    const itf = new Interface(CONTRACT_ABI);
    const calldata = calls.map((call) => [
      call.address.toLowerCase(),
      itf.encodeFunctionData(call.name, call.params),
    ]);
    // console.log(calls, "callsl aRRAY");
    var { returnData } = await multi.methods.aggregate(calldata).call();

    const res = returnData.map((call, i) =>
      itf.decodeFunctionResult(calls[i].name, call)
    );

    const rarr = res.map((item, i) => {
      const [active] = item;
      const obj1 = { ...arr[i] };
      const obj = { active: active, ...obj1 };
      return { ...obj };
    });

    return rarr;
  } catch (e) {
    console.log(e);
  }
}

export function getBalance(userAddress) {
  return new Promise((resolve, reject) => {
    web3.eth
      .getBalance(userAddress)
      .then((d) => {
        resolve(d / 1e18);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function TokenBalnce(userAddress) {
  const data = await token_instance.methods.balanceOf(userAddress).call();
  return data;
}

export async function buyNewLevel(
  level,
  amount,
  address,
  setBuyNewLoading,
  dispatch
) {
  //console.log(level, amount, address, "buy:::::");
  try {
    setBuyNewLoading(true);
    token_instance.methods
      .balanceOf(address)
      .call()
      .then((res) => {
        const userBalance = res / 1e18;
        console.log(res, "userBalance");
        if (userBalance >= amount) {
          token_instance.methods
            .allowance(address, contract_instance._address)
            .call()
            .then(async (res) => {
              const allowance = res / 1e18;

              if (allowance >= amount) {
                try {
                  const gasPrice = await web3.eth.getGasPrice();
                  const gas = await contract_instance.methods
                    .buyNewLevel(level)
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });

                  const data = contract_instance.methods
                    .buyNewLevel(level)
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: gas,
                    });

                  toast
                    .promise(data, {
                      loading: <b>registeration Pending...</b>,
                      success: () => {
                        return <b>registeration Successful</b>;
                      },
                      error: () => {
                        return <b>registeration Canceld.</b>;
                      },
                    })
                    .then((d) => {
                      fetchActiveLevel(address, dispatch);
                      setBuyNewLoading(false);
                    })
                    .catch((err) => {
                      setBuyNewLoading(false);
                    });
                } catch (error) {
                  setBuyNewLoading(false);
                  console.log(error, "Error");
                }
              } else if (allowance < amount) {
                console.log("6");
                console.log((amount * 1e18).toString(), "amount*1e18 3");
                try {
                  const gasPrice = await web3.eth.getGasPrice();
                  const gas = await token_instance.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });
                  const approveData = token_instance.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: gas,
                    });

                  toast
                    .promise(approveData, {
                      loading: <b>Approval Pending...</b>,
                      success: <b>Approval Successful</b>,
                      error: <b>Approval request failed.</b>,
                    })
                    .then(async () => {
                      const gasPrice = await web3.eth.getGasPrice();
                      const gas = await contract_instance.methods
                        .buyNewLevel(level)
                        .estimateGas({
                          from: address,
                          value: 0,
                          gasPrice: gasPrice,
                        });
                      const data = contract_instance.methods
                        .buyNewLevel(level)
                        .send({
                          from: address,
                          value: 0,
                          gasPrice: gasPrice,
                          gas: gas,
                        });

                      toast
                        .promise(data, {
                          loading: <b> Transaction Pending...</b>,
                          success: () => {
                            return <b>Transaction Successful</b>;
                          },
                          error: () => {
                            return <b>Transaction Canceld.</b>;
                          },
                        })
                        .then((d) => {
                          setBuyNewLoading(false);
                          fetchActiveLevel(address, dispatch);
                        })
                        .catch((error) => {
                          setBuyNewLoading(false);
                        });
                    })
                    .catch((err) => {
                      setBuyNewLoading(false);
                      console.log(err, "Err-4");
                    });
                } catch (error) {
                  setBuyNewLoading(false);
                  console.log(error, "approval Error");
                }
              }
            })
            .catch((err) => {
              setBuyNewLoading(false);
              console.log(err, "Err-2");
            });
        } else {
          setBuyNewLoading(false);
          toast.error("Insufficient Balance !");
        }
      })
      .catch((err) => {
        setBuyNewLoading(false);
        console.log(err, "Err -1");
      });
  } catch (error) {
    setBuyNewLoading(false);
    console.log(error);
  }
}

export async function IsOwner(userAddress) {
  const data = await contract_instance.methods.owner().call();
  return data;
}
