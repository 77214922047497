import React from "react";
import backVideo from "../image/rev.mp4";
import "../css/Home/about.css";
import paymentImg from "../image/metamask.jpg";
import rewardImg from "../image/walletImg.jpg";
import moneyImg from "../image/smartMeta.jpg";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <>
      <section className="about-container">
        <div className="yt-video-container">
          <div className="timer-video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/abteVoJs4_8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </div>
        </div>
      </section>
      <section>
        <div className="about-text-container ">
          <div className="about-video-background-top">
            <video
              autoPlay={true}
              loop
              muted
              playsInline
              src={backVideo}
              preload="auto"
              className="about-video"
            >
              <source src={backVideo} type="video/mp4" />
            </video>
          </div>
          <div className="about-video-background-bottom">
            <video
              autoPlay={true}
              loop
              muted
              playsInline
              src={backVideo}
              preload="auto"
              className="about-video"
            >
              <source src={backVideo} type="video/mp4" />
            </video>
          </div>
          <div className="text-top-heading container">
            <div className="text-top-heading-inner">
              <h2 className="section-title">What exactly is Volcano Money?</h2>
              <p className="section-desc">
                Volcano Money is a global Cryptocurrency Ecosystem whose members
                assist one another in achieving financial well-being through the
                use of our tools and algorithm of instant profit distribution on
                smart contracts.
              </p>
              <p className="section-desc">
                The Volcano Money system is completely decentralised and belongs
                to our community, which means it is transparent, secure, and
                resistant to outside influences.
              </p>

              <div className="box-hero-container">
                <div className="box-inner-hero">
                  <div className="section-desc">
                    A smart contract is an algorithm that runs on its own
                    (program code). It ensures the transparency and security of
                    logic execution and transfers within the blockchain.
                  </div>
                </div>
              </div>
              <p className="section-desc">
                The Volcano platform is open and accessible to all. The process of
                joining the community and making money is so simple that all you
                need is a laptop or a smartphone with an Internet connection.
                Create matrix platforms and invite partners to the structure in
                exchange for POLYGON cryptocurrencies.
              </p>
              <div className="box-hero-container">
                <div className="box-inner-hero">
                  <div className="section-desc">
                  No risk - Unlike other platforms, Volcano Money transactions are processed in a blockchain by a smart contract and cannot be changed or stopped even by the creators. Transfers are made directly to the personal wallets of participants, with no hidden fees or the use of third-party resources. That is, every reward earned belongs entirely to the individual and can be used right away.
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="about-grid-image">
                  <img
                    src={paymentImg}
                    alt="imgAboutGrid"
                    className="imgAboutGrid"
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="about-grid-image">
                  <img
                    src={rewardImg}
                    alt="imgAboutGrid"
                    className="imgAboutGrid"
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="about-grid-image">
                  <img
                    src={moneyImg}
                    alt="imgAboutGrid"
                    className="imgAboutGrid"
                  />
                </div>
              </div>
            </div>

            <div className="academy-btn-top d-flex justify-content-center">
              <Link to="" className="academy-btn">
                Learn more at the Academy
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
