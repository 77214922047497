import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../css/Home/auth.css";
import { verifyUser } from "../helpers/helperFunction";
import { IsOwner } from "../helpers/web3";
import navimg from "../image/logo.png";

const Login = () => {
  const { walletAddress, userExist, message } = useSelector(
    (state) => state.user.value
  );
  const dispatch = useDispatch();
  const [ownerview, setOwnerView] = useState(false);
  const [address, setAddress] = useState("");
  const [admin, setAdmin] = useState("");
  const [cw, setCW] = useState("");

  useEffect(() => {
    IsOwner().then((res) => {
      setAdmin(res);
    });
    if (walletAddress) {
      setCW(walletAddress);
    }
  }, [walletAddress]);

  return (
    <>
      <section className="main-dash-container">
        <div className="siginin-section">
          <div className="signin-middle-container">
            <div className="container">
              <div className="col-lg-6 col-md-8 shadow  m-auto">
                <div className="card outer-form-signin">
                  <div className="card-body">
                    <h5 className="text-white fw-normal h4">Login</h5>
                    <div className="mb-2 mt-4">
                      <Link to="/">
                        <img
                          src={navimg}
                          className="nav-image"
                          alt=""
                          width={60}
                        />
                      </Link>
                    </div>
                    <div className="inner-form-signin col-md-12 py-0">
                      <div className="row">
                        <div className="col-md-12">
                          {ownerview ? (
                            <input
                              type="text"
                              id="ip2"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              className=" form-control"
                              placeholder="Enter user Address"
                            />
                          ) : (
                            <input
                              type="text"
                              id="ip2"
                              readOnly
                              value={walletAddress}
                              className="form-control"
                              placeholder=""
                            />
                          )}

                        
                          <div className="mt-2 d-grid">
                            <button
                              class="btn btn-primary  "
                              onClick={() => {
                                // console.log(admin);
                                if (
                                  cw.toLowerCase() === admin.toLowerCase() ||
                                  ownerview
                                ) {
                                  if (address) {
                                    verifyUser(dispatch, address, toast);
                                  } else {
                                    toast.error("Enter wallet address");
                                  }
                                } else {
                                  verifyUser(dispatch, "", toast);
                                }
                              }}
                            >
                              Automatic login
                            </button>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row pb-4 auth-foot">
                        <div className="col-md-12 py-2">
                          <span className="text-secondary">
                          don't have an account? {" "}
                          </span>
                          <a href="register" className="ms-1">
                            Register Here
                          </a>
                        </div>

                        <div>
                            <span className="text-secondary">Note : </span>
                            <span
                              className="text-login-heading text-secondary"
                              style={{ fontSize: "14px" }}
                            >
                              {message}!
                            </span>
                          </div>
                        <div className="col-md-6">
                          {/* <p className="text-secondary pt-3">
                        Official chat Telegram:
                      </p>
                      <h4 className="tele-color">@metalifeglobal.space</h4>*/}
                          {/* {console.log(cw.toLowerCase() ,admin.toLowerCase() ,"admin::::")} */}
                          {/* {cw.toLowerCase() === admin.toLowerCase() ? (
                        <button
                          className=" btn btn-info tele-color"
                          onClick={() => {setOwnerView(true)}}
                        >
                          Admin Login
                        </button>
                      ) : null}  */}

                          {/* <button
                          className=" btn btn-info tele-color"
                          onClick={() => {setOwnerView(true)}}
                        >
                          Admin Login
                        </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
