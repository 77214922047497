import React, { useEffect, useState } from "react";

import { AiFillWallet, AiOutlineUsergroupAdd } from "react-icons/ai";
import {
  checkUsersActiveX6Level,
  getBalance,
  IsOwner,
  TokenBalnce,
  users,
} from "../helpers/web3";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../helpers/redux/dataSlice";
import { Link } from "react-router-dom";
import {
  copy_contract,
  copy_refferal,
  copy_wallet,
  fetchUpgradeIncome,
} from "../helpers/helperFunction";
import { MdContentCopy } from "react-icons/md";
import navimg from "../image/logo.png";
import { GiTakeMyMoney, GiTwoCoins } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { FaRecycle } from "react-icons/fa";
import { IoIosWallet } from "react-icons/io";
import { BsArrowUpRight } from "react-icons/bs";
import { getTotalTeam, getUserRandomIdetail } from "../helpers/api";
import { Loader } from "./Loader";
import { RiCoinsLine, RiCopperCoinFill } from "react-icons/ri";
import { CONTRACT_ADDRESS } from "../helpers/config";

const DashSection = () => {
  const [loading, setLoading] = useState(true);
  const [allParticipants, setAllParticipants] = useState(0);
  const [maticBalance, setBalanceMatic] = useState("");
  const [randomUserId, setUserRandomId] = useState("");
  const [tokenBalance, getBalanceToken] = useState("");
  const [ownerAddress, setOwnerAddress] = useState("");
  const [dashData, setDashData] = useState([]);
  const [recyclewalletIncome, setRecycleWalletIncome] = useState(0);
  const [personalwalletIncome, setPersonalWalletIncome] = useState(0);
  const level = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const { walletAddress, userInfo } = useSelector((state) => state.user.value);
  const dispatch = useDispatch();

  useEffect(() => {
    users(walletAddress).then((res) => {
      dispatch(setUserInfo({ userInfo: res }));
    });
    getBalance(walletAddress).then((res) => {
      setBalanceMatic(res);
    });
    TokenBalnce(walletAddress).then((res) => {
      getBalanceToken(res);
    });
    getTotalTeam(walletAddress).then((res) => {
      setAllParticipants(res);
    });

    IsOwner().then((res) => {
      setOwnerAddress(res.toLowerCase());
    });
  }, [walletAddress, loading]);

  useEffect(() => {
    if (walletAddress) {
      fetchUpgradeIncome(
        walletAddress,
        setRecycleWalletIncome,
        setPersonalWalletIncome
      );
    }
  }, [walletAddress]);

  useEffect(() => {
    var tt;
    if (userInfo?.id) {
      tt = setInterval(() => {
        getUserRandomIdetail(userInfo?.id).then((res) => {
          // console.log(res, "res11");
          if (res?.length > 0) {
            // console.log(res, "res");
            setLoading(false);
            clearInterval(tt);
            setUserRandomId(res[0]?.user_id);
            setDashData(res[0]);
          }
        });
      }, 2000);
    }
    return () => clearInterval(tt);
  }, [walletAddress, userInfo?.id]);

  // console.log(dashData?.referrer, "dashData");
  return (
    <>
      <div className="container">
        {loading ? (
          <Loader />
        ) : (
          <div
            className=""
            id="ContentPlaceHolder1_UpdatePanel1"
            style={{ marginTop: "120px" }}
          >
            <div className="row custom_header">
              <div class="col-md-3 col-sm-6 col-12 logo_div">
                <div className="d-flex justify-content-center pb-2">
                  <div>
                    <img
                      src={navimg}
                      className="nav-image"
                      alt="nav-img"
                      height={50}
                    />
                  </div>
                  <div>
                    <h5 className="text-white ps-2"> ID : {randomUserId}</h5>
                    <p className="text-warning ps-2">
                      WalletAddres : {walletAddress?.slice(0, 5)}...
                      {walletAddress?.slice(-7)}
                    </p>
                    <p className="text-white p-0 ">
                      {" "}
                      Upline : {dashData?.referred_id}{" "}
                    </p>
                    <p className="text-info">
                      {" "}
                      Upline Address : {dashData?.referrer?.slice(0, 5)}...
                      {dashData?.referrer?.slice(-7)}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-9 col-sm-6 col-12 text-end btn_div">
                <button type="button" class="btn btn-primary me-3 mb-3">
                  {maticBalance ? maticBalance?.toFixed(4) : 0} Matic
                </button>
                <button type="button" class="btn btn-primary me-3 mb-3">
                  {" "}
                  {walletAddress.slice(0, 4)}...
                  {walletAddress.slice(-3)} <AiFillWallet />
                </button>
                <button type="button" class="btn btn-primary mb-3">
                  {(tokenBalance / 1e18)?.toFixed(2)} Dai
                </button>
              </div>
            </div>
            <div class="widget-box mb-4">
              <div class="widget-body">
                <div class="box-content">
                  <div class="form-horizontal">
                    <br />

                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-2 text-center">
                          <h5 className="text-white pb-3 dash-heading">
                            Slot Details :
                          </h5>
                        </div>

                        <div class="col-md-8 text-center">
                          <div class="myslots">
                            {level.map((data, i) => {
                              return (
                                <LevelRow level={data} key={i + "slotdetail"} />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <Link to="/workingTree">
                          <div className="text-center mt-3">
                            <button
                              type="button"
                              class="btn btn-primary preview-button"
                            >
                              Preview <BsArrowUpRight />
                            </button>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="widget-body111">
              <div class="row">
                <div class="col-md-3 col-sm-4 col-6 sdsdsd ">
                  <Link to="/partners">
                    <div class="infobox">
                      <div class="infobox-icon bg-light-warning">
                        <AiOutlineUsergroupAdd size={35} />
                      </div>
                      <div class="infobox-data">
                        <span class="infobox-data-number text-white text-white">
                          {userInfo?.partnersCount}
                        </span>
                        <div class="infobox-content"> Direct Partners</div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div class="col-md-3 col-sm-4 col-6 ">
                  <div class="infobox">
                    <div class="infobox-icon bg-light-warning">
                      <HiUserGroup size={35} />
                    </div>
                    <div class="infobox-data">
                      <span class="infobox-data-number text-white">
                        {allParticipants}
                      </span>
                      <div class="infobox-content">Total Team Partners</div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-4 col-6 ">
                  <Link to="/userDirectIncome">
                    <div class="infobox">
                      <div class="infobox-icon bg-light-warning">
                        <RiCopperCoinFill size={35} />
                      </div>
                      <div class="infobox-data">
                        <span class="infobox-data-number text-white">
                          {userInfo?.directIncome / 1e18}
                          <span class="dai">&nbsp;</span>
                        </span>
                        <div class="infobox-content">Direct Income</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div class="col-md-3 col-sm-4 col-6 ">
                  <Link to="/coinWalletIncome">
                    <div class="infobox">
                      <div class="infobox-icon bg-light-warning">
                      <img src={navimg} className="nav-image" alt=""  height={40} />
                      </div>
                      <div class="infobox-data">
                        <span class="infobox-data-number text-white">
                          {(userInfo?.coinwallet / 1e18) * 10}
                          {/* <span class="dai">&nbsp;</span> */}
                        </span>
                        <div class="infobox-content">Volcano Coin</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div class="col-md-3 col-sm-4 col-6 ">
                  <Link to="/userPoolIncome">
                    <div class="infobox">
                      <div class="infobox-icon bg-light-warning">
                        <GiTwoCoins size={35} />
                      </div>
                      <div class="infobox-data">
                        <span class="infobox-data-number text-white">
                          {(userInfo?.personalIncome / 1e18)?.toFixed(2)}{" "}
                          <span class="dai">&nbsp;</span>
                        </span>

                        <div class="infobox-content">Pool Income</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div class="col-md-3 col-sm-4 col-6 ">
                  <Link to="/reinvestDeduction">
                    <div class="infobox">
                      <div class="infobox-icon bg-light-warning">
                        <FaRecycle size={35} />
                      </div>
                      <div class="infobox-data">
                        <span class="infobox-data-number text-white">
                          {recyclewalletIncome.toFixed(2)}
                          {/* {userInfo?.personalIncome / 1e18}{" "} */}
                          <span class="dai">&nbsp;</span>
                        </span>

                        <div class="infobox-content">Recycle Wallet</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div class="col-md-3 col-sm-4 col-6 ">
                  <div class="infobox">
                    <div class="infobox-icon bg-light-warning">
                      <IoIosWallet size={35} />
                    </div>

                    <div class="infobox-data">
                      <span class="infobox-data-number text-white">
                        {ownerAddress === walletAddress
                          ? 0
                          : personalwalletIncome -
                              userInfo?.personalIncome / 1e18 <
                            0
                          ? 0
                          : (
                              personalwalletIncome -
                              userInfo?.personalIncome / 1e18
                            ).toFixed(2)}
                        {/* {userInfo?.personalIncome / 1e18}{" "} */}
                        <span class="dai">&nbsp;</span>
                      </span>

                      <div class="infobox-content">Upgrade Wallet</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-4 col-6 ">
                  <div class="infobox">
                    <div class="infobox-icon bg-light-warning">
                      <GiTakeMyMoney size={35} />
                    </div>
                    <div class="infobox-data">
                      <span class="infobox-data-number text-white">
                      
                        {(
                          userInfo?.personalIncome / 1e18 +
                          userInfo?.directIncome / 1e18 +
                          (ownerAddress === walletAddress
                            ? 0
                            : personalwalletIncome -
                                userInfo?.personalIncome / 1e18 <
                              0
                            ? 0
                            : personalwalletIncome -
                              userInfo?.personalIncome / 1e18) +
                          recyclewalletIncome
                        ).toFixed(2)}{" "}
                        <span class="dai">&nbsp;</span>
                      </span>

                      <div class="infobox-content">Total Income</div>
                    </div>
                  </div>
                </div>

                {/* <div class="col-md-3 col-sm-4 col-6 ">
                  <div class="infobox">
                    <div class="infobox-icon bg-light-warning">
                      <GiTakeMyMoney size={35} />
                    </div>
                    <div class="infobox-data">
                      <span class="infobox-data-number text-white">
                        {userInfo?.personalIncome / 1e18 +
                          userInfo?.directIncome / 1e18 
                      }{" "}
                        <span class="dai">&nbsp;</span>
                      </span>

                      <div class="infobox-content">Total Income</div>
                    </div>
                  </div>
                </div> */}
                <div class="col-md-4 col-sm-3 col-xs-12">
                  <div class="infobox d-block">
                    <div>
                      <p class="infobox-content d-block">My Refral Link:</p>

                      <div className="input-group mb-3 address">
                        <input
                          id="copy"
                          type="text"
                          readOnly
                          className="form-control"
                          value={`https://volcano.money/register?${randomUserId}`}
                        />
                        <button
                          className="input-group-text"
                          onClick={() =>
                            copy_refferal(
                              `https://volcano.money/register?${randomUserId}`
                            )
                          }
                        >
                          <MdContentCopy />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-3 col-xs-12">
                  <div class="infobox d-block">
                    <div>
                      <p class="infobox-content d-block">Wallet Address:</p>

                      <div className="input-group mb-3">
                        <input
                          id="copywallet"
                          type="text"
                          readOnly
                          className="form-control"
                          value={walletAddress}
                        />
                        <button
                          className="input-group-text"
                          onClick={() => copy_wallet(walletAddress)}
                        >
                          <MdContentCopy />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="col-md-4 col-sm-3 col-xs-12">
                  <div class="infobox d-block">
                    <div>
                      <p class="infobox-content d-block">Contract Address:</p>

                      <div className="input-group mb-3">
                        <input
                          id="copycontract"
                          type="text"
                          readOnly
                          className="form-control"
                          value={CONTRACT_ADDRESS}
                        />
                        <button
                          className="input-group-text"
                          onClick={copy_contract}
                        >
                          <MdContentCopy />
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const LevelRow = (props) => {
  const [levelstatus, setLevelStatus] = useState(false);
  const { walletAddress } = useSelector((state) => state.user.value);
  useEffect(() => {
    checkUsersActiveX6Level(walletAddress, props.level).then((res) => {
      //console.log(res,"level::::")
      setLevelStatus(res);
    });
  }, [walletAddress]);
  return (
    <div
      id="ContentPlaceHolder1_l1"
      class={`slot-item ${levelstatus ? "active" : ""}`}
    >
      {props.level}
    </div>
  );
};
export default DashSection;
