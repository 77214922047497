import { getAllUsersWorkingTree, getAllUserupgradeIncome } from "./api";
import { toast } from "react-toastify";
import { CONTRACT_ADDRESS, recyclePercent, upgragePercent } from "./config";
import {
  setRefresh,
  setUserExist,
  setWalletAddress,
  setWorkingTreeArray,
} from "./redux/dataSlice";
import { getLevelMulticall, isUserExists, startNow } from "./web3";
import Decimal from "decimal.js";

export function copy_refferal(refral) {
  var copyText = document.getElementById("copy");
  copyText.select();
  document.execCommand("copy");
  toast.success(refral);
}

export function copy_wallet(wallet) {
  var copyText = document.getElementById("copywallet");
  copyText.select();
  document.execCommand("copy");
  toast.success(wallet);
}

export function copy_contract() {
  var copyText = document.getElementById("copycontract");
  copyText.select();
  document.execCommand("copy");
  toast.success("Contract address Copied!");
}

export function sideBar() {
  var btn = document.querySelector(".toggle");
  var btnst = true;
  btn.onclick = function () {
    if (btnst == true) {
      document.querySelector(".toggle span").classList.add("toggle");
      document.getElementById("sidebar").classList.add("sidebarshow");
      btnst = false;
    } else if (btnst == false) {
      document.querySelector(".toggle span").classList.remove("toggle");
      document.getElementById("sidebar").classList.remove("sidebarshow");
      btnst = true;
    }
  };
}

export function verifyUser(dispatch, address, toast) {
  startNow()
    .then((res) => {
      const fadd = address ? address : res?.userAddress;
      localStorage.setItem("volcano_waddress", fadd);
      dispatch(setWalletAddress({ walletAddress: fadd }));
      isUserExists(fadd).then((res) => {
        if (res) {
          dispatch(setUserExist({ userExist: res }));
          dispatch(setRefresh({ data: true }));
          toast.success("Signin Successfuly  !");
        } else {
          dispatch(setUserExist({ userExist: res }));
          dispatch(setRefresh({ data: true }));
          toast.error("User Not Exist !");
        }
      });
    })
    .catch((e) => {
      console.log("errorinstartnow", e);
    });
}

export function getActiveWallet() {
  return startNow()
    .then((res) => res)
    .catch((e) => {
      console.log("errorinstartnow", e);
    });
}

export async function fetchActiveLevel(walletAddress, dispatch) {
  try {
    getAllUsersWorkingTree(walletAddress)
      .then(async (res) => {
        // fetchis_active(res);
        if (res.length > 0) {
          const resarr = res.map((item, i) => {
            const obj = {
              address: CONTRACT_ADDRESS,
              name: "usersActiveX6Levels",
              params: [walletAddress, i + 1],
            };
            return { ...obj };
          });
          const result = await getLevelMulticall(resarr, res);

          dispatch(setWorkingTreeArray({ data: result }));
        } else {
          dispatch(setWorkingTreeArray({ data: [] }));
        }
      })
      .catch((e) => console.log("checklevelactiveerror::", e));
  } catch (e) {
    console.log("checkactivemulti::", e);
  }
}

export async function fetchUpgradeIncome(
  walletAddress,
  setRecycleWalletIncome,
  setPersonalWalletIncome
) {
  const res = await getAllUserupgradeIncome(walletAddress);
  if (res) {
    let recycleincome = 0;
    const pincome = upgragePercent.reduce((total, current, i) => {
      recycleincome += Number(
        new Decimal(res[i]?.total_member).mul(recyclePercent[i]).valueOf()
      );
      return (total += Number(
        new Decimal(res[i]?.total_member).mul(current).valueOf()
      ));
    }, 0);
    setRecycleWalletIncome(recycleincome);
    setPersonalWalletIncome(pincome);
  } else {
    return [];
  }
}
export function toPlainString(num) {
  return ("" + +num).replace(
    /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function (a, b, c, d, e) {
      return e < 0
        ? b + "0." + Array(1 - e - c.length).join(0) + c + d
        : b + c + d + Array(e - d.length + 1).join(0);
    }
  );
}