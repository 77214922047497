import React from "react";
import '../css/Home/earn.css'
import paymentImg from "../image/earn-1.webp";
import rewardImg from "../image/earn-2.webp";
import moneyImg from "../image/earn-3.webp";
const Work = () => {
  return (
    <>
      <div className="earn-container">
        <div className="container">
        
            <h2 className="section-title">
              How do I start earning with the Volcano Money?
            </h2>
            <div className="row">
              <div className="col-md-4">
                <div className="hero-grid-item">
                  <div className="hero-grid-left">
                    <img src={paymentImg} alt="firstHeroGrid" />
                  </div>
                  <p className="hero-grid-right large-text">
                    {" "}
                    Make a wallet and fill it with DAI and MATIC (to pay transaction fees).
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="hero-grid-item">
                  <div className="hero-grid-left">
                    <img src={rewardImg} alt="secondHeroGrid" />
                  </div>
                  <p className="hero-grid-right large-text">
                    {" "}
                    Sign up for the site. If you were invited by someone, use his referral link. 
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="hero-grid-item">
                  <div className="hero-grid-left">
                    <img src={moneyImg} alt="thirdHeroGrid" />
                  </div>
                  <p className="hero-grid-right large-text">
                    {" "}
                    Increase your income by activating levels in Volcano Money systems. 
                  </p>
                </div>
              </div>
            </div>
          </div>
       
      </div>
    </>
  );
};

export default Work;
