import React from "react";
import "../css/Home/banner.css";
import backVideo from "../image/volcano.mp4";
import heroImg from "../image/main_hero.png";
import paymentImg from "../image/payment.png";
import rewardImg from "../image/reward.png";
import moneyImg from "../image/money.png";
import { Link } from "react-router-dom";
const Banner = () => {
  return (
    <>
      <section className="hero-banner">
        <div className="hero-video">
          <video
            autoPlay={true}
            loop
            muted
            playsInline
            src={backVideo}
            preload="auto"
            className="video"
          >
            <source src={backVideo} type="video/mp4" />
          </video>
        </div>
        <div className="hero-container container">
          <div className="row">
            <div className="col-md-6  banner-content-column">
              <p className="hero-suptitle">Volcano</p>
              <h1 className="hero-title">Money</h1>
              <p className="hero-desc ">DeFi platform №1</p>
              <Link
                to="/register"
                className="hero-connect glob-btn glob-btn--primary"
              >
                {" "}
                Join{" "}
              </Link>
            </div>
            <div className="col-md-6 banner-image-column" >
              <div className="hero-animal">
                <img
                  src={heroImg}
                  alt="hero"
                  className="banner-animate-image vert-move"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="hero-grid-item">
                <div className="hero-grid-left">
                  <img src={paymentImg} alt="firstHeroGrid" />
                </div>
                <p className="hero-grid-right large-text">
                  {" "}
                  Instant payments via smart contract to your wallet{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="hero-grid-item">
                <div className="hero-grid-left">
                  <img src={rewardImg} alt="secondHeroGrid" />
                </div>
                <p className="hero-grid-right large-text">
                  {" "}
                  100% of the turnover becomes the income of the participants{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="hero-grid-item">
                <div className="hero-grid-left">
                  <img src={moneyImg} alt="thirdHeroGrid" />
                </div>
                <p className="hero-grid-right large-text">
                  {" "}
                  A community where each other helps to make money.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
