import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { Pagination } from "@mui/material";
import ToggleSidebar from "./ToggleSidebar";
import { getAllUserReinvestIncome } from "../helpers/api";
import { useSelector } from "react-redux";
import { Loader } from "./Loader";
export default function ReinvestDeduction() {
    const [apidata, setApidata] = useState();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const { walletAddress } = useSelector((state) => state.user.value);
    
    useEffect(() => {
        getAllUserReinvestIncome(walletAddress, page).then((res) => {      
        setTotal(res?.total);
        setApidata(res?.data);
        setLoading(true)
      });
      
    }, [walletAddress]);
  
    const handleChange = (e, p) => {
    
        getAllUserReinvestIncome(walletAddress, p).then((res) => {
        setTotal(res?.total);
        setApidata(res?.data);
        setLoading(true)
      });
  
      setPage(p);
    };

  return (
    <>
       <section className="main-dash-container">
        <ToggleSidebar />
        <div className="container" style={{ paddingTop: "100px" }}>
          <h2 className="text-center text-warning pb-2 dash-heading">Recycle Income Details</h2>
          {loading === false ? (
                <Loader/>
              ) : (
          <div className="row">
            <div className="table-responsive table-dark user-table user-dash-section  p-2">
              <table class="table table-borderless table-striped">
                <thead>
                  <tr>
                    <th className="table-head dash-heading"># </th>
                    <th className="table-head dash-heading" style={{ minWidth: "133px" }}>Trxn Hash</th>
                    <th className="table-head dash-heading" style={{ minWidth: "63px" }}>Id</th>
                   
                    <th className="table-head dash-heading" style={{ minWidth: "93px" }}>Amount</th>
                    <th className="table-head dash-heading" style={{ minWidth: "93px" }}>
                      Income Type
                    </th>
                    <th className="table-head" style={{ minWidth: "101px" }}>
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="table-data">
                  {apidata?.length > 0 ? (
                    apidata?.map((e, index) => {
                    
                      return (
                        <tr
                          className="  p-2"
                          style={{ marginBlock: "0px 15px" }}
                          key={index}
                        >
                          <td className="">
                            <span>{index + 1}</span>
                          </td>
                          <td className="">
                            <span>
                              {e?.transaction_id.slice(0, 6)}...
                              {e?.transaction_id.slice(-5)}
                            </span>
                          </td>
                          <td className="">
                            <span>{e?.user_id}</span>
                          </td>
                         
                          <td className="">
                            <span>{e.amount / 1e18} Dai</span>
                          </td>
                          <td className="">
                            <span>
                              Reinvest Income
                            </span>
                          </td>
                          <td className="">
                            
                            {moment(e.block_timestamp * 1000).fromNow()}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className="text-center text-white mt-4 mb-2 fw-bold fs-5 dash-heading">
                          No Data Found.
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="d-flex justify-content-center mb-3 mt-3">
                <Pagination
                  color="primary"
                  count={Math.ceil(total / 10)}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>)}
        </div>
      </section>
    </>
  )
}
