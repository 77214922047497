import React, { useEffect, useState } from "react";
import ToggleSidebar from "./ToggleSidebar";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { usersX6HoldAmount, withdrawHoldingAnount } from "../helpers/web3";
import "../css/Home/dash.css";
import { Pagination } from "@mui/material";
const WorkingTable = () => {
  const [apidata, setApidata] = useState([]);
  const [page, setPage] = useState(1);
  const [apiS6length, setapiS6length] = useState();
  const [apiS6Paginate, setapiS6Paginate] = useState();
  const [refresh, setRefresh] = useState(false);
  const [holdingAmt, setHoldingAmt] = useState(0);
  const { workingTreeArray, walletAddress } = useSelector(
    (state) => state.user.value
  );
  const params = useParams();
  const { level } = params;
  useEffect(() => {
    if (workingTreeArray.length > 0) {
      const arr = [...workingTreeArray[level - 1].users];
      setApidata(arr.reverse());
      setapiS6length(arr.length);
      setapiS6Paginate(arr.slice(0, 10));
    }

    usersX6HoldAmount(walletAddress, level).then((res) => {
      setHoldingAmt(res);
    });
  }, [workingTreeArray, params, walletAddress, refresh]);

  function handleChange(e, p) {
    setPage(p);
    let limit = 10;
    const startIndex = (p - 1 + 1) * limit - limit;
    const endIndex = startIndex + limit;
    setapiS6Paginate(apidata.slice(startIndex, endIndex));
  }
  return (
    <>
      <section className="main-dash-container">
        <ToggleSidebar />
        <div className="container" style={{ paddingTop: "100px" }}>
          <h2 className="text-center text-warning pb-2 dash-heading">
            Volcano S6 Patners Details
          </h2>
          <div className="row">
            <div className="table-responsive table-dark user-table user-dash-section  p-2">
              {holdingAmt > 0 ? (
                <div className="margin-auto">
                  <div
                    class="col-md-3 col-sm-3 col-xs-12 "
                    style={{ margin: "auto" }}
                  >
                    <div
                      class="infobox d-block"
                      style={{
                        background: "#0b2c1b",
                        padding: "5px",
                        borderRadius: "5px",
                        border: " 1px solid rgb(69 189 53 / 96%)",
                      }}
                    >
                      <div>
                        <div class="infobox-content d-block text-center pt-2 dash-heading">
                          Withdrawal Deduction :{" "}
                          <span className="text-danger fw-bolder">24.92%</span>
                        </div>

                        <div className="pt-2 ps-3 pb-2 text-center">
                          <span className="text-white dash-heading">
                            Holding Amount :{" "}
                          </span>
                          <span className="text-white fw-bold pe-2">
                            {holdingAmt / 1e18} Dai{" "}
                          </span>
                          <div className="text-center mt-2">
                            <button
                              type="button"
                              style={{
                                background: "#009a00 !important",
                                border: " 1px solid rgb(69 189 53 / 96%)",
                              }}
                              class="btn text-white bg-info  "
                              onClick={() =>
                                withdrawHoldingAnount(
                                  walletAddress,
                                  level,
                                  setRefresh
                                )
                              }
                            >
                              withdraw
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <table class="table table-borderless table-striped">
                <thead>
                  <tr>
                    <th className="table-head dash-heading"># </th>
                    <th className="table-head dash-heading">User ID</th>
                    <th className="table-head dash-heading">User Address</th>
                    <th className="table-head dash-heading">Place</th>
                    {/* <th className="table-head">Registration Date</th> */}
                  </tr>
                </thead>

                <tbody className="table-data">
                  {apiS6Paginate?.length > 0 ? (
                    apiS6Paginate?.map((e, index) => {
                      return (
                        <tr
                          className="  p-2"
                          style={{ marginBlock: "0px 15px" }}
                          key={index + "directincome"}
                        >
                          <td className="">
                            <span>{index + 1}</span>
                          </td>
                          <td className="">
                            <span>{e?.user_id}</span>
                          </td>
                          <td className="">
                            <span>
                              {e?.user?.slice(0, 6) +
                                "..." +
                                e?.user?.slice(30)}
                            </span>
                          </td>
                          <td className="fw-bolder">
                            <span>
                            {/* {e?.place} */}
                              {e?.place == 1
                                ? "A"
                                : e?.place == 2
                                ? "B"
                                : e?.place == 3
                                ? "A1"
                                : e?.place == 4
                                ? "A2"
                                : e?.place == 5
                                ? "B1"
                                : "B2"}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        {" "}
                        <div className="text-center text-white mt-4 mb-2 fw-bold fs-5 dash-heading">
                          No Data Found.
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="d-flex justify-content-center mb-3 mt-3">
                <Pagination
                  count={Math.ceil(apidata.length / 10)}
                  onChange={handleChange}
                  page={page}
                  // variant="outlined"
                  color="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkingTable;
