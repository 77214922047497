import axios from "axios";
export const getUserRandomIdetail = async (userId) => {
 
  try {
    const data = await axios.get(
      `https://api.volcano.money/api/registration_details/${userId}`
    );
   
    return data.data.data;
  } catch (e) {
    // console.log('dincatch:',e)
    return [];
  }
};

export const getTotalTeam = async(userAddress) =>{
 
  const data = await axios.get(
    `https://api.volcano.money/api/direct_referrals/${userAddress}`
  );
  
  return data.data.data;
}

export const getUserRandomId = async (randomId) => {
  try {
    const data = await axios.get(
      `https://api.volcano.money/api/registration/${randomId}`
    );

    return data.data.data;
  } catch (e) {
    // console.log('dincatch:',e)
    return [];
  }
};

export const getDirectTeam = async (userId ,page) => {

  try {
    const data = await axios.get(
      `https://api.volcano.money/api/registration_referral/${userId}?page=${page}`
    );
   
    return data.data.data;
  } catch (e) {
    // console.log('dincatch:',e)
    return [];
  }
};

export const getAllUsersIncome = async (userAddress,page) => {
  try {
    const data = await axios.get(
      `https://api.volcano.money/api/user_income/${userAddress}?page=${page}`
    );

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const getAllUsersWorkingTree = async (userAddress) => {
  const data = await axios.get(
    `https://api.volcano.money/api/new_user_place/${userAddress}`
  );

  return data.data.data;
};

export const getAllUserupgradeIncome = async (userAddress) => {
  const data = await axios.get(
    `https://api.volcano.money/api/new_user_place_list/${userAddress}`
  );

  return data.data.data;
  
};

export const getAllUserReinvestIncome = async (userAddress,page) => {
  try {
  const data = await axios.get(
    `https://api.volcano.money/api/user_income_deduction/${userAddress}?page=${page}`
  );

  return data.data.data;
} catch (e) {
  return [];
}
};
export const getAllUsersPoolIncome = async (userAddress,page) => {
  try {
    const data = await axios.get(
      `https://api.volcano.money/api/user_personal_income/${userAddress}?page=${page}`
    );

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const getAllUsersDirectIncome = async (userAddress,page) => {
  try {
    const data = await axios.get(
      `https://api.volcano.money/api/user_personal_introducer_income/${userAddress}?page=${page}`
    );

    return data.data.data;
  } catch (e) {
    return [];
  }
};

export const getUserCoinWalletIncome = async (userAddress,page) => {
  try {
    const data = await axios.get(
      `https://api.volcano.money/api/user_personal_coin_wallet_income/${userAddress}?page=${page}`
    );

    return data.data.data;
  } catch (e) {
    return [];
  }
};