import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineDeveloperMode } from "react-icons/md";
import "../css/Home/roadmap.css";
const Roadmap = () => {
  return (
    <>
      <div className="earn-container">
        <div className="container">
          <main>
            <section class="py-5">
              <div class="container">
                <h2 className="section-title">Volcano Money is on the ROADMAP</h2>

                <div class="container-timeline">
                  <ul>
                    <li>
                      <p class="h6 fw-bold">Planning Team Logic Foundation</p>
                    </li>
                    <li>
                      <p class="h6 fw-bold">Design Development Airdrop</p>
                    </li>
                    <li>
                      <p class="h6 fw-bold">IEO Self Exchange PR Marketing </p>
                    </li>
                    <li>
                      <p class="h6 fw-bold">CMC CoinGeeko CoinDesk</p>
                    </li>
                    <li>
                      <p class="h6 fw-bold">Wallet NFT</p>
                    </li>
                    <li>
                      <p class="h6 fw-bold">Defi Design & Planning Blockchain</p>
                    </li>
                    <li>
                      <p class="h6 fw-bold">BlockChain  Testnet Integrated Wallet</p>
                    </li>
                    <li>
                      <p class="h6 fw-bold">BlockChain Mainnet  Explorer Developers Tools</p>
                    </li>
                   
                  </ul>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
