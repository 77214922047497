import React from 'react'
import "../css/Home/mission.css";
import mission from "../image/mission.webp";
import vision from "../image/vision.webp";
import value from "../image/value.webp";
const Mission = () => {
  return (
    <div>
       <section className="our_mission_area ">
        <h1 className="section-title pt-5">
          Mission Vision Value
        </h1>
        <div className="container">
          <div className="row pb-5">
            <div className="col-md-4 mission-column mb-4">
              <div className="mission-inner-card">
                <img src={mission} alt="img" className="img-mission" />

                <div className="mission-content">
                  <h1 className="career-text text-center fs-3 fw-bolder">
                    Our Mission
                  </h1>
                  <p className="large-text pt-2">
                    We wanted to start a crypto revolution in the world. In this
                    age when crypto is falling so badly, we want to develop a
                    crypto currency that is sustainable and uncrashable by big
                    sharks of the game. We are constructing the financial
                    future.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mission-column mb-4">
              <div className="mission-inner-card">
                <img src={vision} alt="img" className="img-mission" />

                <div className="mission-content">
                  <h1 className="career-text text-center fs-3 fw-bolder">
                    Our Vision
                  </h1>
                  <p className="large-text pt-2">
                    Our vision is to make Volcano money available to every corner of
                    the world. We are looking forward to run the company for a
                    very long time. Out director believes crypto is sustainable
                    so we are looking forward to make that vison in reality.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mission-column mb-4">
              <div className="mission-inner-card">
                <img src={value} alt="img" className="img-mission" />
                <div className="mission-content">
                  <h1 className="career-text text-center fs-3 fw-bolder">
                    Our Value
                  </h1>
                  <p className="large-text pt-2">
                    We believes that money of a investor is so valuable. When
                    there is so much frauds going on in world through crypto
                    companies, we wanted to appreciate time and money of our
                    investors by providing them the best suitable profit
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Mission
