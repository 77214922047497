import React from "react";
import "../css/Home/loader.css";
export const Loader = () => {
  return (
    <>
      <div class="body-loader">
        <div class="container-loader">
          <div class="wrapper-loader">
            <div class="loader">
              <div class="dot"></div>
            </div>
            <div class="loader">
              <div class="dot"></div>
            </div>
            <div class="loader">
              <div class="dot"></div>
            </div>
            <div class="loader">
              <div class="dot"></div>
            </div>
            <div class="loader">
              <div class="dot"></div>
            </div>
            <div class="loader">
              <div class="dot"></div>
            </div>
          </div>
          <div class="text">Please wait</div>
        </div>
      </div>
    </>
  );
};
