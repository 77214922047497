import React from "react";
import "../css/Home/earn.css";

const Earn = () => {
  return (
    <>
      <div className="earn-container">
        <div className="container">
          <div className="row">
            <h2 className="section-title">How does it work?</h2>
            <div className="box-hero-container">
              <div className="box-inner-hero">
                <div className="section-desc">
                  Volcano Money is a platform that combines a referral program and an
                  automatic algorithm for distributing affiliate rewards. The
                  affiliate links in Volcano Money are transparent: they are tied
                  and thanks to the referral link you can see who invited whom,
                  or who is a upline and who is a downline partner.
                </div>
              </div>
            </div>
            <div className="earn-para-section">
              <p className="section-desc">
                The platform Volcano Money is open and available to everyone. The
                process of entering the community and starting to make a profit
                is so simple that you only need a laptop or a smartphone with an
                Internet connection. Develop matrix platforms and invite
                partners to the structure, for which you get remuneration in
                POLYGON cryptocurrencies.
              </p>
            </div>
            <div className="box-hero-container">
              <div className="box-inner-hero">
                <p className=" section-desc text-center">UniteVerse</p>
                <div className="section-desc">
                  An upgraded system that is suitable for beginners and
                  experienced participants alike
                </div>
                <p className=" section-desc text-center">COMING SOON</p>
              </div>
            </div>
            <div className="box-hero-container">
              <div className="box-inner-hero">
                <p className=" section-desc text-center">Boost</p>
                <div className="section-desc">
                  Boost is designed for those who want to make a powerful leap
                  in income growth and are ready for hard work. Boost combines
                  all the best of the best.
                </div>
                <p className=" section-desc text-center">COMING SOON</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Earn;
