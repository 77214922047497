import React, { useEffect } from "react";
import "../../src/css/Home/dash.css";
import DashSection from "../Pages/DashSection";
import ToggleSidebar from "../Pages/ToggleSidebar";



const Dashboard = () => {

  return (
    <>
      <section className="main-dash-container">
        <ToggleSidebar/>
        <DashSection />
      </section>
    </>
  );
};

export default Dashboard;
